import React from 'react'
import Helmet from 'react-helmet'

import HeaderBar from './HeaderBar'
import FooterBar from './FooterBar'
import '../style.scss'
import './Layout.scss'

const TemplateWrapper = ({ children, headerClasses }) => (
  <div>
    <Helmet title="Home | Mobility Labs - Do More With Technology" />
    <HeaderBar classes={headerClasses} />
    <div>{children}</div>
    <FooterBar />
  </div>
)

export default TemplateWrapper
