import {
  FaFacebookF,
  FaGithub,
  FaLinkedin,
  FaTwitter,
} from 'react-icons/fa'
export default [
  {
    icon: FaLinkedin,
    name: "LinkedIn",
    href: "https://www.linkedin.com/company/mobility-labs"
  },
  {
    icon: FaFacebookF,
    name: "Facebook",
    href: "http://www.facebook.com/mobilitylabs"
  },
  {
    icon: FaTwitter,
    name: "Twitter",
    href: "http://www.twitter.com/mobilitylabs"
  },
  {
    icon: FaGithub,
    name: "Github",
    href: "http://github.com/mobilitylabs"
  },
]
