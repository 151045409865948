import React, {Component} from 'react';
import {Link} from 'gatsby';

import './FooterBar.scss';
import logo from '../img/logo.svg';
import socialList from './socialList';

export default class FooterBar extends Component {
  render() {
    const socialLinks = socialList.map((social, i) => {
      return (
          <a target="_blank" href={social.href} key={i} className="btn btn-link">
            <social.icon size={32}/>
          </a>
        )
      }
    );

    return (
      <footer>
        <div className="container-fluid">
          <div className="container">
            <div className="row">
              <div className="col">
                <Link to="/" >
                  <img alt="Mobility Labs logo" src={logo} className="logo"/>
                </Link>
              </div>
            </div>
            <div className="row">
              <div className="col metaInfo">
                <a href="tel:+1-202-656-2681">1.202.656.2681</a>
                <p>777 6th St NW,<br/> Washington, DC 20001</p>
              </div>
              <div className="col-6 socialLinks">
                {socialLinks}
              </div>
            </div>
          </div>
        </div>
      </footer>
    )
  }
}
