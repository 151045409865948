import React, {Component} from 'react';
import './ContactForm.scss';

export default class ContactForm extends Component {
  state = {
    success: false,
    error: false,
  }
  handleSubmit = e => {
    e.preventDefault()
    e.persist() // Lets keep the event around
    const {target} = e
    const formData = {
      email: target.email.value,
      additional: target.additional.value,
      'form-name': target['form-name'].value,
      'bot-field': target['bot-field'].value,
    }

    this.sendFormData(formData)
    .then(response => {
      if (response.ok) {
        this.setState({error: false, success: true})
        e.target.reset()
      } else {
        this.setState({error: true})
      }
    })
    .catch(err => {
      this.setState({error: true})
    })
  }

  sendFormData = json => {
    const body = Object.keys(json)
      .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(json[key]))
      .join('&')
    return fetch(
      '/contact/',
      {
        method: 'post',
        body: body,
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      }
    )
  }

  render() {
    const {error, success} = this.state
    return (
      <div
        id="contact-form"
        className="contact-form"
      >
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h1>Contact Us</h1>
              {
                success ?
                (
                  <div>
                    <p>Your message has been sent. We will get back to you in the next 48 hours.</p>
                  </div>
                ) : (
                  <form
                    onSubmit={this.handleSubmit}
                    name="contact"
                    netlify-honeypot="bot-field"
                    data-netlify="true"
                    action="/contact/"
                  >
                    <input type="hidden" name="form-name" value="contact" />
                    <div className="form-group">
                      <label htmlFor="formGroupEmailAddress">Email Address</label>
                      <input
                        name="email"
                        type="email"
                        className="form-control"
                        id="email"
                        placeholder="Email Address"
                        required="required"
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="formGroupMessage">Message</label>
                      <textarea
                        name="additional"
                        className="form-control"
                        id="additional"
                        placeholder="Message"
                        rows="3"
                        required="required"
                     />
                    </div>
                    <label className="d-none">Don’t fill this out if you're human: <input name="bot-field"/></label>
                    <button type="submit" className="btn btn-primary">Submit</button>
                    {
                      error &&
                      <span className="form-error ml-3">An error has occurred. Try reloading and submitting again.</span>
                    }
                  </form>
                )
              }
            </div>
          </div>
        </div>
      </div>
    )
  }
}
