import React from 'react'
import Layout from '../components/Layout'
import ContactForm from '../components/ContactForm'

const Contact = () => (
  <Layout>
    <div>
      <ContactForm/>
    </div>
  </Layout>
)

export default Contact
