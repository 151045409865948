import React, {Component} from 'react';
import {Link} from 'gatsby';
import './HeaderBar.scss';
import logo from '../img/logo.svg';
import logoIcon from '../img/logo-icon.svg';

export default class HeaderBar extends Component {
  goToContact = () => {
    const contactForm = document.getElementById('contact-form');
    if (contactForm) {
      contactForm.scrollIntoView(true)
    }
  }

  render() {
    return (
      <header className="header-bar">
        <div className="container">
          <div className="row">
            <div className="col header-content">
              <Link to="/">
                <img className="logo d-block d-md-none" src={logoIcon} alt="Mobility Labs, Inc. logo"/>
                <img className="logo d-none d-md-block" src={logo} alt="Mobility Labs, Inc. logo"/>
              </Link>
              <ul className="nav nav-inline">
                <li className="nav-item">
                  <Link className="nav-link" to="/case-studies">Case Studies</Link>
                </li>
                <li className="nav-item">
                  <a className="nav-link" onClick={this.goToContact} style={{cursor: 'pointer'}}>Contact</a>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/blog">Blog</Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </header>
    )
  }
}
